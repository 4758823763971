import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const GridContainer = styled(motion.div)`
  height: 100%;
  margin: 0 auto;
  flex-grow: 1;
  text-align: center;
  width: 100%;
  height: calc(100vh - 84px);
  position: relative;

  > button {
    position: fixed;
    bottom: 2%;
    right: 2%;
    background-color: #fff;
    border: none;
    height: 3rem;
    width: 3rem;
    padding: 0;

    svg {
      display: block;
      margin: auto;
      height: 3rem;
      width: 3rem;
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const CardComponent = styled.div`
  background-color: ${({ theme }) => theme.colors.cardBg};
  width: 100%;
  height: 300px;
`;

export const IframeWrapper = styled(motion.div)`
  width: 100%;
  height: calc(100vh - 84px);
  overflow: auto;
  position: relative;
`;

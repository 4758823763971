import * as S from './styled';

const Loader = () => {
  return (
    <S.LoaderContainer>
      <S.ExtraLargeSpinner />
    </S.LoaderContainer>
  );
};

export default Loader;

import React, { useState } from 'react';
import ConatactImage from 'src/assets/images/contact-us.svg';
import * as S from './styled';

type MailtoProps = {
  email: string;
  subject: string;
  body: string;
  children: any;
};

const Mailto: React.FC<MailtoProps> = ({ email, subject, body, children }) => {
  return (
    <a
      href={`mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
        body
      )}`}
    >
      {children}
    </a>
  );
};

const ContactUs = () => {
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const email = 'alchemy@informa.com';

  const handleReset = () => {
    setSubject('');
    setMessage('');
  };

  return (
    <S.ContactUsContainer>
      <S.ContainerHead>
        <S.ImgContent>
          <img src={ConatactImage} />
        </S.ImgContent>
        <h2>Contact us with your queries / suggestions</h2>
      </S.ContainerHead>
      <S.ContentBody>
        <label>Subject</label>
        <S.SubjectInput
          name="ContactName"
          value={subject}
          onChange={e => setSubject(e.target.value)}
          className="subjectInput"
          type="text"
          placeholder="Type subject here"
        />
        <label>Message</label>
        <S.MessageInput
          name="ContactComment"
          value={message}
          className="messageInput"
          onChange={e => setMessage(e.target.value)}
          // type="text"
          placeholder="Type your message here"
        />
      </S.ContentBody>
      <S.ButtonContainer>
        <S.ButtonReset onClick={handleReset} className="buttonRest">
          Reset
        </S.ButtonReset>
        <Mailto email={email} subject={subject} body={message}>
          <S.ButtonSubmit disabled={!subject || !message} className="buttonSubmit">
            Submit
          </S.ButtonSubmit>
        </Mailto>
      </S.ButtonContainer>
    </S.ContactUsContainer>
  );
};

export default ContactUs;

import { useEffect } from 'react';
import { useAnimation, motion } from 'framer-motion';
import { NavLink } from 'react-router-dom';
import * as S from './styled';
import { ReactComponent as BackArrow } from 'src/assets/images/arrow-back.svg';
import { ReactComponent as AlchemyLogo } from 'src/assets/images/alchemy.svg';
import { ReactComponent as TopCircleLogo } from 'src/assets/images/Top-Circle.svg';
import BottomCircleLogo from 'src/assets/images/Bottom-Circle.svg';
import { ReactComponent as MenuIcon } from 'src/assets/images/menu.svg';
import { ReactComponent as DashboardIcon } from 'src/assets/images/dashboard.svg';
import { ReactComponent as FaqIcon } from 'src/assets/images/faq.svg';
import { ReactComponent as MessageIcon } from 'src/assets/images/message.svg';
import { SidebarProps } from './types';
import { StatefulPopover, PLACEMENT, TRIGGER_TYPE } from 'baseui/popover';

export const SidebarContent: React.FC<SidebarProps> = ({
  minimized,
  setMinimized,
  setSidebarMenuIsOpen,
}) => (
  <>
    <S.DashboardLogoContainer minimized={minimized}>
      {!minimized ? (
        <>
          <motion.p key="iirislogo" variants={S.SpanVariant} initial="initial" animate="animate">
            <AlchemyLogo />
          </motion.p>
          <motion.div
            key="topcirclelogo"
            variants={S.SpanVariant}
            initial="initial"
            animate="animate"
          >
            <TopCircleLogo />
          </motion.div>
          <motion.button
            variants={S.SpanVariant}
            initial="initial"
            animate="animate"
            key="btn"
            onClick={() => {
              if (setSidebarMenuIsOpen) {
                setSidebarMenuIsOpen(false);
              } else {
                setMinimized(true);
              }
            }}
          >
            <BackArrow />
          </motion.button>
        </>
      ) : (
        <motion.button
          key="menuBtn"
          variants={S.SpanVariant}
          initial="initial"
          animate="animate"
          onClick={() => {
            setMinimized(false);
          }}
        >
          <MenuIcon />
        </motion.button>
      )}
    </S.DashboardLogoContainer>
    <S.NavUl minimized={minimized}>
      <S.NavLi>
        <NavLink to="/dashboard" exact>
          <StatefulPopover
            overrides={{
              Body: {
                style: { zIndex: 30 },
              },
            }}
            placement={PLACEMENT.right}
            content={() => <S.PopupContent>Dashboard</S.PopupContent>}
            returnFocus
            autoFocus
            triggerType={TRIGGER_TYPE.hover}
          >
            <DashboardIcon />
          </StatefulPopover>
          {!minimized && (
            <>
              <motion.span variants={S.SpanVariant} initial="initial" animate="animate">
                Dashboard
              </motion.span>
            </>
          )}
        </NavLink>
      </S.NavLi>
      <S.NavLi>
        <NavLink to="/dashboard/user-guide">
          <StatefulPopover
            overrides={{
              Body: {
                style: { zIndex: 30 },
              },
            }}
            placement={PLACEMENT.right}
            content={() => <S.PopupContent>User guide / FAQ</S.PopupContent>}
            returnFocus
            autoFocus
            triggerType={TRIGGER_TYPE.hover}
          >
            <FaqIcon />
          </StatefulPopover>
          {!minimized && (
            <motion.span variants={S.SpanVariant} initial="initial" animate="animate">
              User guide / FAQ
            </motion.span>
          )}
        </NavLink>
      </S.NavLi>
      <S.NavLi>
        <NavLink to="/dashboard/contact-us">
          <StatefulPopover
            overrides={{
              Body: {
                style: { zIndex: 30 },
              },
            }}
            placement={PLACEMENT.right}
            content={() => <S.PopupContent>Contact Us</S.PopupContent>}
            returnFocus
            autoFocus
            triggerType={TRIGGER_TYPE.hover}
          >
            <MessageIcon />
          </StatefulPopover>
          {!minimized && (
            <motion.span variants={S.SpanVariant} initial="initial" animate="animate">
              Contact Us
            </motion.span>
          )}
        </NavLink>
      </S.NavLi>
    </S.NavUl>
    <S.NavFooter minimized={minimized}>
      <img src={BottomCircleLogo} />

      {!minimized && (
        <motion.span variants={S.SpanVariant} initial="initial" animate="animate">
          Powered By <b>IIRIS</b>
        </motion.span>
      )}
    </S.NavFooter>
  </>
);

const Sidebar: React.FC<SidebarProps> = ({ minimized, setMinimized }) => {
  const control = useAnimation();
  useEffect(() => {
    if (minimized) {
      control.start({
        width: '6.375rem',
        transition: {
          duration: 0.4,
        },
      });
    } else {
      control.start({
        width: '21.875rem',
        transition: {
          duration: 0.3,
        },
      });
    }
  }, [control, minimized]);
  return (
    <S.DashboardSidebar animate={control} className="p-0 d-none d-xxl-block">
      <SidebarContent minimized={minimized} setMinimized={setMinimized} />
    </S.DashboardSidebar>
  );
};

export default Sidebar;

// If you add any property to this file, be sure to add the type declarations in emotion.d.ts file

const theme = {
  colors: {
    primary: '#002244',
    secondary: '#4735cd',
    tertiary: '#373B3C',
    cardBg: '#CCD4F5',
    gray: '#707070',
    blueBorder: '#057AFF',
    btnHoverColor: '#1905B3',
    bgColor: '#EEF8FC',
    focusedInputBg: '#EAFAFF',
    borderColor: '#A6BCC6',
    dashboardBorderColor: '#C3D0D6',
    error: 'red',
    required: 'red',
    white: '#fff',
    black: '#000',
    menuColor: '#002CCC',
  },
  breakpoint: '50rem',
};

export default theme;

import { useLayoutEffect, useEffect, useRef, useState } from 'react';
import { toaster } from 'baseui/toast';
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary';
import Loader from 'src/components/Loader/Loader';
import * as S from './styled';
import { Auth } from 'aws-amplify';
import './style.css';

const Dashboard = ({ minimized }: any) => {
  const ref = useRef(null);
  const [width, setWidth] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [content, setContent] = useState<string>('');
  const [toastKey, setToastKey] = useState<any>(null);
  const apiRoot = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';

  useEffect(() => {
    if (ref.current) {
      setWidth((ref.current as any).clientWidth - 50);
    }
  }, [minimized]);

  useEffect(() => {
    if (content !== '') {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  }, [content]);

  useLayoutEffect(() => {
    async function getContent() {
      try {
        const session = await Auth.currentSession();
        if (content.length === 0) {
          const payload = {};
          const res = await fetch(`${apiRoot}/v2/domo/embedurl`, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          });
          if (res.status === 200) {
            const responseText = await res.text();
            const responseJson = JSON.parse(responseText);
            const frameContent = `<html><head><style>  .app-body-main .page-embed_pageHeader_1bBcb{display:none !important;}</style></head><body><form id="form" action="https://public.domo.com/embed/pages/v276X" method="post"><input type="hidden" name="embedToken" value="${responseJson.data.embed_token}"></form><script>document.forms[0].submit();</script></body></html>`;
            setContent(frameContent);
          } else {
            const frameContent = `<html><head></head><body>You do not have access to view dashboard</body></html>`;
            setContent(frameContent);
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        setIsLoading(false);
        setToastKey(toaster.negative('Something went wrong', {}));
      }
    }
    getContent();
  }, [apiRoot, content.length]);

  useEffect(() => {
    let removeToast: any;
    if (toastKey) {
      removeToast = setTimeout(() => {
        toaster.clear(toastKey);
      }, 2000);
    }
    return () => {
      clearTimeout(removeToast);
    };
  }, [toastKey]);

  const calculateHeight = (width: number) => {
    let IframeHeight = '5000px';
    if (width > 1400 && width < 1700) {
      IframeHeight = '4000px';
    } else if (width < 1400 && width >= 1300) {
      IframeHeight = '3800px';
    } else if (width < 1300 && width >= 1000) {
      IframeHeight = '3500px';
    } else if (width < 1000 && width >= 800) {
      IframeHeight = '11500px';
    } else if (width < 800 && width >= 730) {
      IframeHeight = '10000px';
    } else if (width < 730 && width >= 500) {
      IframeHeight = '9000px';
    } else if (width < 500 && width >= 350) {
      IframeHeight = '6000px';
    } else if (width < 350) {
      IframeHeight = '4300px';
    }
    return IframeHeight;
  };

  return (
    <S.GridContainer
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="gridContent"
    >
      {isLoading && <Loader />}
      <ErrorBoundary>
        <S.IframeWrapper ref={ref} layout>
          <iframe
            width={width.toString()}
            height={calculateHeight(width)}
            frameBorder={0}
            srcDoc={content}
            className="frameContent "
          />
        </S.IframeWrapper>
      </ErrorBoundary>
    </S.GridContainer>
  );
};

export default Dashboard;

import { useState } from 'react';
import { NavLink } from "react-router-dom";
import { Accordion, Panel } from 'baseui/accordion';
import { Modal, ModalBody } from 'baseui/modal';
import { motion } from 'framer-motion';
import * as S from './styled';
import faq1 from 'src/assets/images/faq/faq1.png';
import faq3 from 'src/assets/images/faq/faq3.png';
import faq4 from 'src/assets/images/faq/faq4.png';
import faq5 from 'src/assets/images/faq/faq5.png';

const FAQAccordion = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedImg, setSelectedImg] = useState<string>('');

  const close = () => {
    setIsOpen(false);
  };

  const handleImageClick = (selectedImg: string) => {
    setIsOpen(true);
    setSelectedImg(selectedImg);
  };

  return (
    <>
      <Modal
        unstable_ModalBackdropScroll
        onClose={close}
        isOpen={isOpen}
        overrides={{
          Root: {
            style: S.ModalRootStyles,
          },
          Dialog: {
            style: S.ModalDialogStyles as any,
          },
          Close: {
            style: S.ModalCloseStyles as any,
          },
        }}
      >
        <ModalBody className="imageClose" style={S.ModalBodyStyles(selectedImg)} />
      </Modal>

      <Accordion
        overrides={{
          Root: {
            style: {
              fontFamily: 'inherit',
            },
          },
          PanelContainer: {
            style: S.AccordionPanelContainerStyles,
          },
          Content: {
            style: S.AccordionContentStyles,
          },
          Header: {
            style: ({ $expanded }) => S.AccordionHeaderStyles($expanded) as any,
          },
          ToggleIcon: {
            style: ({ $expanded }) => S.AccordionHeaderStyles($expanded) as any,
          },
        }}
        // eslint-disable-next-line no-console
        onChange={({ expanded }) => console.log(expanded)}
      >
        <Panel title="What is Alchemy?">
          <div className='row'>
            <div className='col-sm-4'>
              <S.PanelImageContainer onClick={() => handleImageClick(faq1)}>
                <motion.img whileHover={S.HoverStyles} src={faq1} alt="faq-image" />
              </S.PanelImageContainer>
            </div>
            <div className='col-sm-8'>
              <S.PanelPTag>
                Alchemy is Informa's lead delivery platform. It collates data captured from various event platforms and identifies which delegates interacted with your event presence, either virtually, or in person. This information is then displayed in an interactive dashboard, providing you with a customisable view of your leads and the content which generated them.
              </S.PanelPTag>
            </div>
          </div>
        </Panel>

        <Panel title="How do I navigate the dashboard?">
          <div className='row'>
            <div className='col-sm-12'>
              <S.PanelPTag>
                Please refer to the resources below:
              </S.PanelPTag>
              <S.PanelPTag>
                <div className="px-sm-2"><strong><a href="https://assets.informa.com/alchemy/Alchemy%20User%20Guide%202023.pdf" target="_blank" rel="noreferrer">User Guide</a></strong></div>
                <div className="px-sm-2"><strong><a href="https://assets.informa.com/alchemy/Alchemy%20Walk%20Through%20Video.mp4" target="_blank" rel="noreferrer">Demo Video</a></strong></div>
              </S.PanelPTag>
            </div>
          </div>
        </Panel>

        <Panel title="What are 'Activity Types'?">
          <div className='row'>
            <div className='col-sm-4'>
              <S.PanelImageContainer onClick={() => handleImageClick(faq3)}>
                <motion.img whileHover={S.HoverStyles} src={faq3} alt="faq-image" />
              </S.PanelImageContainer>
            </div>
            <div className='col-sm-8'>
              <S.PanelPTag>
                'Activity Types' are the data capture points, so essentially all the ways that delegates can interact with your event presence. Please refer to the image attached to see the definitions of each activity type.
              </S.PanelPTag>
            </div>
          </div>
        </Panel>

        <Panel title="How do we calculate the 'Engagement Score'?">
          <div className='row'>
            <div className='col-sm-4'>
              <S.PanelImageContainer onClick={() => handleImageClick(faq4)}>
                <motion.img whileHover={S.HoverStyles} src={faq4} alt="faq-image" />
              </S.PanelImageContainer>
            </div>
            <div className='col-sm-8'>
              <S.PanelTextContainer>
                <S.PanelPTag>
                  Each activity type is assigned a score based on how meaningful that interaction is. A lead's engagement score is calculated by adding the score of each of their interactions with your event presence. Please refer to the image attached to see how the scores are assigned.
                </S.PanelPTag>
              </S.PanelTextContainer>
            </div>
          </div>
        </Panel>

        <Panel title="How can I export the leads?">
          <div className='row'>
            <div className='col-sm-4'>
              <S.PanelImageContainer onClick={() => handleImageClick(faq5)}>
                <motion.img whileHover={S.HoverStyles} src={faq5} alt="faq-image" />
              </S.PanelImageContainer>
            </div>
            <div className='col-sm-8'>
              <S.PanelPTag>
                Navigate back to the Dashboard page and find the purple 'Export' button. Hover over it and you will see the export icon appear in the top right corner. Click this and the data will export straight to your device in a csv format.
              </S.PanelPTag>
            </div>
          </div>
        </Panel>

        <Panel title="Why aren't all my leads appearing?">
          <div className='row'>
            <div className='col-sm-12'>
              <S.PanelPTag>
                The first thing to check is that you haven't accidentally applied a filter. All the visualisations are interactive, and filters will apply to the whole report. To remove a filter, press the filter icon with a cross through it in the top right corner of the visualisation.
              </S.PanelPTag>
              <S.PanelPTag>
                If you still feel like there are leads missing, please reach out using the <NavLink
                  to="/dashboard/contact-us"
                >
                  <strong>Contact Us</strong>
                </NavLink> tab.
              </S.PanelPTag>
            </div>
          </div>
        </Panel>
      </Accordion>
    </>
  );
};

export default FAQAccordion;
export const borderRadiusHelper = (radius: string) => ({
	borderTopLeftRadius: radius,
	borderTopRightRadius: radius,
	borderBottomLeftRadius: radius,
	borderBottomRightRadius: radius,
  });
  
  export const borderColorHelper = (color: string) => ({
	borderTopColor: color,
	borderBottomColor: color,
	borderLeftColor: color,
	borderRightColor: color,
  });
  
  export const outlineColorHelper = (color: string) => ({
	outlineTopColor: color,
	outlineBottomColor: color,
	outlineLeftColor: color,
	outlineRightColor: color,
  });
  
  export const borderWidthHelper = (width: string) => ({
	borderTopWidth: width,
	borderBottomWidth: width,
	borderLeftWidth: width,
	borderRightWidth: width,
  });
  
  export const marginHelper = (margin: string | number) => ({
	marginTop: margin,
	marginBottom: margin,
	marginLeft: margin,
	marginRight: margin,
  });
  
  export const paddingHelper = (padding: string | number) => ({
	paddingTop: padding,
	paddingBottom: padding,
	paddingLeft: padding,
	paddingRight: padding,
  });
  
  const size: Record<string, string> = {
	mobileS: '320px',
	mobileM: '375px',
	mobileL: '425px',
	tablet: '768px',
	laptop: '1001px',
	laptopL: '1440px',
	laptopXL: '1500px',
	desktop: '2560px',
  };
  
  export const device: Record<string, string> = {
	mobileS: `(min-width: ${size.mobileS})`,
	mobileM: `(min-width: ${size.mobileM})`,
	mobileL: `(min-width: ${size.mobileL})`,
	tablet: `(min-width: ${size.tablet})`,
	laptop: `(min-width: ${size.laptop})`,
	laptopL: `(min-width: ${size.laptopL})`,
	laptopXL: `(min-width: ${size.laptopXL})`,
	desktop: `(min-width: ${size.desktop})`,
  };
import styled from '@emotion/styled';
import { device } from 'src/utils';

export const ContactUsContainer = styled.div`
  padding: 1.5rem;
  overflow: auto;

  @media ${device.tablet} {
    padding-top: 2rem;
  }
  > h6 {
    margin: 0;
    font-size: 22px;
    width: 65%;
    margin-left: auto;
  }
  input {
    width: 100%;
    border: 1px solid hsl(199, 22%, 71%);
    border-radius: 3px;
    margin-top: 5px;
    padding-left: 10px;
    :focus {
      outline-color: #a6bcc6;
      outline-style: solid;
    }
  }
`;

export const ContainerHead = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.cardBg};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
  padding: 4.1rem 3rem;
  margin: 4.2rem 0 3.125rem 0;
  min-height: 10rem;
  @media ${device.tablet} {
    align-items: flex-start;
    width: 68vw;
    display: grid;
    grid-template-columns: 1fr 3fr;
  }
  > h2 {
    font: normal normal bold 22px Open Sans;
    color: ${({ theme }) => theme.colors.primary};
    flex-basis: 150%;
    text-align: center;
  }
`;

export const ImgContent = styled.div`
  position: relative;
  width: 100%;
  height: 0.25rem;

  > img {
    width: 210px;
    height: 190px;
    position: absolute;
    top: -119px;
  }
`;

export const ContentBody = styled.div`
  margin-top: 25px;

  > label {
    margin: 0;
    color: #373b3c;
    font-weight: normal;
    font-size: 16px;
  }
`;

export const SubjectInput = styled.input`
  margin-bottom: 27px;
  height: 40px;
  ::placeholder {
    color: #a6bcc6;
  }
`;

export const MessageInput = styled.textarea`
  height: 160px;
  width: 100%;
  border: 1px solid hsl(199, 22%, 71%);
  border-radius: 3px;
  margin-top: 5px;
  padding-top: 5px;
  padding-left: 10px;
  ::placeholder {
    color: #a6bcc6;
  }
  :focus {
    outline-color: #a6bcc6;
    outline-style: solid;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  button {
    width: 130px;
    border-radius: 4px;
    border: 1px solid #a6bcc6;
    font-weight: 600;
    padding: 8px 10px;
    font-size: 18px;
    :hover {
      cursor: pointer;
    }
  }
`;

export const ButtonReset = styled.button`
  background-color: #ffffff;
  color: #373b3c;
  margin-right: 10px;
  :hover {
    color: #4735cd;
    border: 1px solid #4735cd;
  }
`;

export const ButtonSubmit = styled.button<{disabled: boolean}>`
  background-color: ${({disabled}) => (disabled ? '' : '#4735cd')};
  color: ${({disabled}) => (disabled ? '#525A5C' : '#ffffff')};
  :hover {
  background-color: ${({disabled}) => (disabled ? '' : '#5846dd')};
   color: ${({disabled}) => (disabled ? '#525A5C' : '#ffffff')};

  }
`;
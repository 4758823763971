import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { toaster } from 'baseui/toast';
import Navbar from 'src/components/Navbar';
import Sidebar from 'src/components/Sidebar';
import Dashboard from './Dashboard/Dashboard';
import FAQ from './FAQ/FAQ';
import ContactUs from './ContactUs/ContactUs';
import * as S from './styled';
import { Auth } from 'aws-amplify';
import axios from 'axios';

type DashboardPageProps = {
  login: boolean;
};

const DashboardPage: React.FC<DashboardPageProps> = ({ login }) => {
  const [minimized, setMinimized] = useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();
  const [toastKey, setToastKey] = useState<any>(null);

  useEffect(() => {
    const minimizeTimer = setTimeout(() => {
      if (!minimized) {
        setMinimized(true);
      }
    }, 5000);

    return () => {
      clearTimeout(minimizeTimer);
    };
  }, [minimized]);

  useEffect(() => {
    return history.listen(() => {
      setMinimized(true);
    });
  }, [history]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: user.signInUserSession.accessToken.jwtToken,
          },
          params: {
            readonly: '1',
          },
        };
        const apiRoot = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';
        const audienceApiRoot = process.env.REACT_APP_AUDIENCE_API_URL ? process.env.REACT_APP_AUDIENCE_API_URL : '';
        const first_name = user.attributes.given_name || '';
        const last_name = user.attributes.family_name || '';
        const payload: any = {
          business_email: user.attributes.email || '',
          first_name,
          last_name,
          company: user.attributes.company || '',
          country: user.attributes.country || '',
          state: user.attributes.state || '',
          job_title: user.attributes.job_title || '',
          is_social_login: false,
          business_type: user.attributes.business_type || '',
          job_function: user.attributes.job_function || '',
          social_accounts: [],
        };
        const { data } = await axios.post(`${audienceApiRoot}/v3/audience/profile`, payload, config);
        if (window.IIRISTracker) {
          window.IIRISTracker.setTrackingUID(window.IIRISTracker.setTrackingUID(data.data[0].id));
        }
        localStorage.setItem('audienceApiCalled', JSON.stringify(true));
      } catch (e: any) {
        // eslint-disable-next-line no-console
        console.log(e);
        setToastKey(toaster.negative('Something went wrong', {}));
      }
    };
    if (localStorage.getItem('audienceApiCalled') !== 'true') {
      getUser();
    }
  }, []);

  useEffect(() => {
    let removeToast: any;
    if (toastKey) {
      removeToast = setTimeout(() => {
        toaster.clear(toastKey);
      }, 2000);
    }
    return () => {
      clearTimeout(removeToast);
    };
  }, [toastKey]);

  return (
    <S.DashboardPageContainer>
      <Sidebar minimized={minimized} setMinimized={setMinimized} />
      <S.DashboardContentContainer>
        <Navbar minimized={minimized} setMinimized={setMinimized} />
        <S.Container className="mx-auto mx-xxl-0 ms-xxl-auto" minimized={minimized}>
          <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
              <Route path="/dashboard" exact>
                {login ? <Dashboard minimized={minimized} /> : <Redirect to="/" />}
              </Route>
              <Route path="/dashboard/user-guide">{login ? <FAQ /> : <Redirect to="/" />}</Route>
              <Route path="/dashboard/contact-us">
                {login ? <ContactUs /> : <Redirect to="/" />}
              </Route>
            </Switch>
          </AnimatePresence>
        </S.Container>
      </S.DashboardContentContainer>
    </S.DashboardPageContainer>
  );
};

export default DashboardPage;

import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { StatefulPopover, PLACEMENT, TRIGGER_TYPE } from 'baseui/popover';
import { Auth } from 'aws-amplify';
import * as S from './styled';
import { ReactComponent as LogoutIcon } from 'src/assets/images/logout.svg';
import { ReactComponent as UserIcon } from 'src/assets/images/userdetails.svg';
import { ReactComponent as MenuIcon } from 'src/assets/images/menu.svg';
import { NavbarProps } from './types';
import { borderRadiusHelper } from 'src/utils';
import { Drawer, SIZE, ANCHOR } from 'baseui/drawer';
import { SidebarContent } from '../Sidebar';
import { DrawerOverrides } from 'src/pages/DashboardPage/styled';

declare global {
  interface Window {
    IIRISTracker: any;
  }
}

const Navbar: React.FC<NavbarProps> = ({ minimized, setMinimized }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [userAttr, setUserAttr] = useState<any>(null);
  const [sidebarMenuIsOpen, setSidebarMenuIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (minimized) {
      setSidebarMenuIsOpen(false);
    }
  }, [minimized]);

  useEffect(() => {
    if (sidebarMenuIsOpen) {
      setMinimized(false);
    }
  }, [setMinimized, sidebarMenuIsOpen]);

  let selectedPage = '';
  if (pathname === '/dashboard') {
    selectedPage = 'IIRIS Alchemy';
  } else if (pathname.includes('user-guide')) {
    selectedPage = 'User guide / FAQ';
  } else if (pathname.includes('contact-us')) {
    selectedPage = 'Contact Us';
  }

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      // Clear any old cognito session if it is not cleared by Auth.SignOut
      const items = { ...localStorage };
      for (const a in items) {
        if (a.startsWith('CognitoIdentityServiceProvider') || a.includes('amplify')) {
          localStorage.removeItem(a);
        }
      }
    }

    // Clear Tracking UserID
    if (window.IIRISTracker) {
      window.IIRISTracker.clearTrackingUID();
    }
    localStorage.removeItem('audienceApiCalled');
    history.replace('/');
    window.location.reload();
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      if (user != null && user.attributes !== null) {
        setUserAttr(user.attributes);
      }
    });
  }, []);

  return (
    <S.NavbarContainer
      className="ms-xl-auto d-flex justify-content-between px-4 px-md-5 py-4"
      layout
      minimized={minimized}
    >
      <div className="d-flex align-items-center">
        <S.SidebarOpener
          className="d-xxl-none btn ps-0 me-3"
          onClick={() => setSidebarMenuIsOpen(true)}
        >
          <MenuIcon />
        </S.SidebarOpener>
        <S.NavbarHeading className="fs-3">{selectedPage}</S.NavbarHeading>
      </div>

      <S.NavbarRightItems>
        <S.UserDetails>
          <S.UserName>
            {userAttr?.given_name && userAttr?.given_name.includes('SPEX')
              ? userAttr?.given_name.replace('SPEX', '')
              : userAttr?.given_name}{' '}
            {userAttr?.family_name}
          </S.UserName>
        </S.UserDetails>
        <S.Divider />
        <StatefulPopover
          overrides={{
            Body: {
              style: { zIndex: 30 },
            },
          }}
          placement={PLACEMENT.bottom}
          content={() => <S.PopupContent>Logout</S.PopupContent>}
          returnFocus
          autoFocus
          triggerType={TRIGGER_TYPE.hover}
        >
          <S.LogoutButton
            whileTap={{
              scale: 0.9,
            }}
            onClick={handleSignOut}
          >
            <LogoutIcon />
          </S.LogoutButton>
        </StatefulPopover>
      </S.NavbarRightItems>
      <S.NavMobileViewItems>
        <StatefulPopover
          overrides={{
            Body: {
              style: {
                zIndex: 30,
                ...borderRadiusHelper('.3rem'),
              },
            },
            Inner: {
              style: {
                ...borderRadiusHelper('.3rem'),
              },
            },
          }}
          placement={PLACEMENT.bottomRight}
          content={() => (
            <S.LogoutPopup className="p-3 bg-light rounded-3">
              <S.MobileUser>
                {userAttr?.given_name} {userAttr?.family_name}
              </S.MobileUser>
              <a href="#" onClick={handleSignOut} className="link-dark text-decoration-none">
                {' '}
                <LogoutIcon /> Logout{' '}
              </a>
            </S.LogoutPopup>
          )}
          returnFocus
          autoFocus
        >
          <S.UserButton
            whileTap={{
              scale: 0.9,
            }}
          >
            <UserIcon />
          </S.UserButton>
        </StatefulPopover>
      </S.NavMobileViewItems>

      <Drawer
        overrides={DrawerOverrides(window, true)}
        isOpen={sidebarMenuIsOpen}
        autoFocus
        size={SIZE.full}
        anchor={ANCHOR.left}
      >
        <SidebarContent
          minimized={minimized}
          setMinimized={setMinimized}
          setSidebarMenuIsOpen={setSidebarMenuIsOpen}
        />
      </Drawer>
    </S.NavbarContainer>
  );
};

export default Navbar;

import styled from '@emotion/styled';
import theme from 'src/theme';

export const PanelImageContainer = styled.div`
  min-height: 9rem;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.dashboardBorderColor};
  display: grid;
  place-items: center;
`;

export const PanelTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const AccordionHeaderStyles = ($expanded: boolean | undefined) => ({
  color: $expanded ? theme.colors.secondary : '',
  ':hover': {
    color: theme.colors.secondary,
  },
  paddingLeft: 0,
  paddingRight: 0,
  fontFamily: 'inherit',
  fontWeight: 600,
  backgroundColor: 'transparent',
});

export const AccordionPanelContainerStyles = {
  borderBottomColor: theme.colors.dashboardBorderColor,
};

export const AccordionContentStyles = () => ({
  backgroundColor: 'transparent',
  paddingTop: '.5rem',
  paddingLeft: 0,
  paddingBottom: '1.5rem',
  fontFamily: 'inherit',
  fontSize: '.9rem',
  lineHeight: 1.6,
  paddingRight: 0,
  color: theme.colors.tertiary,
  gridTemplateColumns: '18rem 2fr',
  gridGap: '2rem',
});

export const ModalRootStyles = {
  zIndex: 40,
};

export const ModalDialogStyles = {
  minWidth: '60vw',
  minHeight: '60vh',
  display: 'flex',
  flexDirection: 'column',
};

export const ModalCloseStyles = {
  borderRadius: '50%',
  backgroundColor: 'white !important',
  border: '1px solid #4735CD',
  top: '-10px',
  right: '-10px',
  color: '#4735CD',
};

export const ModalBodyStyles = (bgImage: string) => ({
  flex: '1 1 0',
  background: `url(${bgImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
});

export const Panel = styled.div`
  display: 'flex'
`;

export const HoverStyles = {
  scale: 1.1,
};

export const PanelPTag = styled.p`
  margin: 10px 0 0 0;
`;
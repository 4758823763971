  import * as S from './styled';
import FAQImage from 'src/assets/images/FAQ.png';
import FAQAccordion from 'src/components/FAQAccordion';

const FAQ = () => {
  return (
    <S.GridContainer layout initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <S.FAQPageContainer>
        <S.FAQHeading>
          <S.FAQImageContainer>
            <img src={FAQImage} alt="faq-image" />
          </S.FAQImageContainer>
          <h2 style={{ paddingLeft: '14px' }}>Frequently Asked Questions (FAQs)</h2>
        </S.FAQHeading>
        <FAQAccordion />
      </S.FAQPageContainer>
    </S.GridContainer>
  );
};

export default FAQ;

import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import bgImage from 'src/assets/images/linear-bg.png';

export const GridContainer = styled(motion.div)`
  height: 100%;
  width: 100%;
  // background: url(${bgImage});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 0% 0%;
  padding: 2rem;
`;

export const FAQPageContainer = styled.div`
  max-width: 62rem;
  margin: 0 auto;
`;

export const FAQHeading = styled.div`
  background-color: ${({ theme }) => theme.colors.cardBg};
  border-radius: 1rem;
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  padding: 4.1rem 2rem;
  justify-content: space-between;
  margin: 4.2rem 0 3.125rem 0;
  min-height: 10rem;

  > h2 {
    font: normal normal bold 22px Open Sans;
    color: ${({ theme }) => theme.colors.primary};
    flex-basis: 150%;
    text-align: center;
  }

  @media only screen and (max-width: 680px) {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin: 0;
  }
`;

export const FAQImageContainer = styled.div`
  position: relative;
  height: 1.375rem;
  width: 100%;
  display: flex;

  > img {
    position: absolute;
    bottom: -44px;
    left: 15px;
  }

  @media only screen and (max-width: 680px) {
    display: contents;

    > img {
      position: initial;
    }
  }
`;

import { useState, Suspense, lazy, useEffect } from 'react';
import { ThemeProvider } from '@emotion/react';
import theme from './theme';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { LightTheme, BaseProvider } from 'baseui';
import { ToasterContainer } from 'baseui/toast';
import Loader from './components/Loader/Loader';
import { Auth } from 'aws-amplify';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import { useIdleTimer } from 'react-idle-timer';

const LoginPageLazy = lazy(() => import('./pages/LoginPage/LoginPage'));
const NotFoundPageLazy = lazy(() => import('./pages/404Page/404Page'));

const engine = new Styletron();

declare global {
  interface Window {
    IIRISTracker: any;
  }
}

function App() {
  const [login, setLogin] = useState<boolean>(false);
  const history = useHistory();

  const handleOnIdle = async () => {
    getLastActiveTime();
    try {
      await Auth.signOut();
    } catch (error) {
      // Clear any old cognito session if it is not cleared by Auth.SignOut
      const items = { ...localStorage };
      for (const a in items) {
        if (a.startsWith('CognitoIdentityServiceProvider') || a.includes('amplify')) {
          localStorage.removeItem(a);
        }
      }
    }

    // Clear Tracking UserID
    if (window.IIRISTracker) {
      window.IIRISTracker.clearTrackingUID();
    }
    history.replace('/');
    window.location.reload();
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  useEffect(() => {
    const checkUser = async () => {
      try {
        const sessionData = await Auth.currentSession();
        if (sessionData.getAccessToken().getJwtToken()) {
          setLogin(true);
        }
      } catch (e) {
        setLogin(false);
      }
    };
    checkUser();
  }, []);

  useEffect(() => {
    if (login) {
      history.push('/dashboard');
    }
  }, [history, login]);

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        <ThemeProvider theme={theme}>
          <ToasterContainer />
          <Switch>
            <Route path="/" exact>
              <Suspense fallback={<Loader />}>
                <LoginPageLazy setLogin={setLogin} />
              </Suspense>
            </Route>

            <Route path="/dashboard">
              {login ? <DashboardPage login={login} /> : <Redirect to="/" />}
            </Route>

            <Route>
              <Suspense fallback={<Loader />}>
                <NotFoundPageLazy />
              </Suspense>
            </Route>
          </Switch>
        </ThemeProvider>
      </BaseProvider>
    </StyletronProvider>
  );
}

export default App;

import styled from '@emotion/styled';
import { withStyle } from 'baseui';
import { StyledSpinnerNext } from 'baseui/spinner';
import theme from 'src/theme';

export const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: transparent(180deg, #ebf0ff 0%, #ffffff 100%) 0% 0%;
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ExtraLargeSpinner = withStyle(StyledSpinnerNext, {
  width: '96px',
  height: '96px',
  borderLeftWidth: '12px',
  borderRightWidth: '12px',
  borderTopWidth: '12px',
  borderBottomWidth: '12px',
  borderTopColor: theme.colors.secondary,
});

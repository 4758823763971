import styled from '@emotion/styled';
import bgImage from 'src/assets/images/linear-bg.png';
import theme from 'src/theme';
import { borderColorHelper, borderRadiusHelper } from 'src/utils';

export const DashboardPageContainer = styled.div`
  height: 100vh;
  display: flex;
  // background: url(${bgImage});
  background: transparent linear-gradient(180deg, #ccecfa 0%, #ffffff 40%) 0% 0% no-repeat
    padding-box;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 0% 0%;
`;

export const DashboardContentContainer = styled.div`
  flex-basis: 100%;
`;

export const Container = styled.div<{ minimized: boolean }>`
  height: calc(100vh - 5.25rem);
  overflow: auto;
  display: flex;
  justify-content: center;
  @media (min-width: 1400px) {
    width: ${({ minimized }) => (minimized ? 'calc(100% - 6.375rem)' : 'calc(100% - 21.875rem)')};
  }
`;

export const DrawerOverrides = (window: Window, sidebar?: boolean) => ({
  Root: {
    style: {
      zIndex: 45,
    },
    props: {
      id: 'Drawer',
    },
  },
  DrawerBody: {
    style: {
      fontFamily: 'Open Sans, sans-serif',
      marginLeft: sidebar ? 0 : '2rem',
      marginRight: sidebar ? 0 : '2rem',
      overflow: 'visible',
    },
  },
  DrawerContainer: {
    style: {
      width: window.innerWidth < 1000 ? '100vw' : '35vw',
    },
  },
  Close: {
    props: {
      title: 'Close',
    },
    style: {
      display: sidebar ? 'none' : 'flex',
      fill: theme.colors.secondary,
      ...borderRadiusHelper('50%'),
      ...borderColorHelper(theme.colors.secondary),
      marginTop: '1.3rem',
      marginRight: '2rem',
    },
  },
});

import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { device } from 'src/utils';

export const DashboardSidebar = styled(motion.div)`
  width: 21.875rem;
  height: 100vh;
  box-shadow: 0px 0px 20px #00000029;
  position: absolute;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 30;
`;

export const DashboardLogoContainer = styled.div<{ minimized: boolean }>`
  margin: ${({ minimized }) => (minimized ? '0 0 3rem 0' : '0 0 3rem 2.6rem')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5.25rem;

  @media (max-width: 1400px) {
    position: relative;
    top: -32px;
  }

  > p {
    position: relative;
    top: 1rem;
  }

  > div {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  }

  > button {
    border: 1px solid
      ${({ theme, minimized }) =>
        minimized ? theme.colors.white : theme.colors.dashboardBorderColor};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ minimized }) => (minimized ? 0 : '6px 0px 0px 6px')};
    cursor: pointer;
    height: ${({ minimized }) => (minimized ? '5.25rem' : '2rem')};
    width: ${({ minimized }) => (minimized ? '100%' : '2rem')};
    display: flex;
    justify-content: center;
    z-index: 100;
    @media ${device.tablet} {
      background-color: ${({ theme }) => theme.colors.white};
    }

    > svg {
      fill: ${({ theme }) => theme.colors.secondary};
      display: block;
      margin: auto;
      @media ${device.tablet} {
        fill: ${({ theme }) => theme.colors.secondary};
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.secondary};
      border: 1px solid
        ${({ theme, minimized }) =>
          minimized ? theme.colors.dashboardBorderColor : theme.colors.white};

      > svg {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;

export const NavUl = styled.ul<{ minimized: boolean }>`
  list-style: none;
  margin: 0;
  padding: 0;
  ${({ minimized }) => minimized && { visibility: 'hidden', position: 'absolute' }};
  @media ${device.tablet} {
    visibility: visible;
    position: 'relative';
  }
`;

export const NavLi = styled.li`
  padding-left: 2.4rem;
  position: relative;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.tertiary};
    font-size: 1.25rem;
    display: flex;
    gap: 1.1rem;
    margin-bottom: 2.5rem;
    height: 1.68rem;

    > svg {
      fill: ${({ theme }) => theme.colors.tertiary};
      display: block;
      margin: auto 0;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.secondary};

      > svg {
        fill: ${({ theme }) => theme.colors.secondary};
      }
    }
  }

  a.active {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.secondary};

    > svg {
      fill: ${({ theme }) => theme.colors.secondary};
    }

    &::before {
      content: '';
      position: absolute;
      width: 6px;
      height: 100%;
      left: 0;
      background-color: ${({ theme }) => theme.colors.secondary};
      border-radius: 100vw;
    }
  }
`;

export const NavFooter = styled.div<{ minimized: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  > img {
    position: absolute;
    bottom: 0;
  }

  > span {
    display: block;
    position: relative;
    margin: 0 0 1.5rem 2.5rem;
    color: #ffffff;
    font-size: 0.8rem;
  }
`;

export const DVariant = {
  initial: {
    width: 220,
  },
  animate: {
    transition: {
      duration: 0.5,
      delay: 0.25,
    },
  },
};

export const SpanVariant = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 0.25,
    },
  },
  exit: {
    scale: 0.5,
    opacity: 0,
  },
};

export const PopupContent = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  padding: 0.2rem 1rem;
  border-radius: 0.3rem;
`;

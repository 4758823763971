import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { device } from 'src/utils';

export const NavbarContainer = styled(motion.div)<{ minimized: boolean }>`
  width: 100vw;
  height: 5.25rem;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;

  @media (min-width: 1400px) {
    width: ${({ minimized }) => (minimized ? 'calc(100% - 6.375rem)' : 'calc(100% - 21.875rem)')};
  }
`;

export const NavbarHeading = styled.h2`
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: 600;
  font-size: 1.625rem;
  @media ${device.tablet} {
    text-align: start;
  }
`;

export const NavbarRightItems = styled.div`
  display: flex;
  @media (max-width: 766px) {
    display: none;
  }
`;

export const SidebarOpener = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.colors.white};

  > svg {
    fill: ${({ theme }) => theme.colors.secondary};
  }
`;

export const NavMobileViewItems = styled.div`
  display: block;
  @media ${device.tablet} {
    display: none;
  }
`;

export const UserDetails = styled.div`
  margin-right: 2.5rem;
  margin-top: 0.5rem;
`;
export const MobileUser = styled.div`
  margin-bottom: 1rem;
  margin-right: 2.5rem;
`;

export const UserName = styled.p`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
`;

export const UserDescription = styled.p`
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.gray};
`;

export const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.dashboardBorderColor};
  height: 100%;
  width: 1px;
`;

export const TooltipModalStyles = {
  backgroundColor: 'white',
  color: 'black',
};

export const LogoutPopup = styled.div`
  > a:hover {
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: 600;

    > svg {
      fill: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

export const LogoutButton = styled(motion.button)`
  margin-left: 1.5rem;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.dashboardBorderColor};
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;

  > svg {
    fill: ${({ theme }) => theme.colors.secondary};
    display: block;
    margin: auto;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};

    > svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const UserButton = styled(motion.button)`
  margin-left: 1.5rem;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.dashboardBorderColor};
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.1rem;
  height: 2.1rem;
  cursor: pointer;
  > svg {
    fill: ${({ theme }) => theme.colors.secondary};
    margin-right: 1.5px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};

    > svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const PopupContent = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  padding: 0.2rem 1rem;
  border-radius: 0.3rem;
`;
